<script>
export default {
	beforeCreate() {
		const { params } = this.$route;
		const { path } = params;
		this.$router.replace({ path: '/' + path})
	},render: function(h) {
		return h() // avoid warning message
	}
}
</script>
